import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    Avatar,
    Paper,
    Divider,
    Button,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import dev from '../../../services/axios-client';
import HeaderWrapper from '../../Wrapper/HeaderWrapper';
import HeaderText from '../../Text/HeaderText';
import Loader from '../../Loader';
import PrimaryBtn from '../../CustomButton/PrimaryBtn';
import FormRejectionDialog from '../../Popups/formRejectionDialog';
import FormSubmitDialog from '../../Popups/formSubmitDialog';

const fontStyle = {
    fontSize: '16px',
    lineHeight: '22px',
    fontFamily: 'Red Hat Display',
    color: '#6C757D',
    fontWeight: 600,
    margin: '2px 0 7px 0',
};

const IconStyle = {
    width: '47px',
    height: '47px',
    borderRadius: '12px',
    display: 'flex',
    backgroundColor: '#FF285C',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px' /* Adjust font size as needed */,
    fontWeight: 'bold' /* Adjust font weight as needed */,
    textAlign: 'center' /* Center text horizontally */,
    lineHeight: '47px' /* Center text vertically */,
};

const InstagramIcon = (
    <div style={IconStyle}>
        <img src="/InstagramIcon.png" alt="insta" />
    </div>
);
const SnapchatIcon = (
    <div style={{ ...IconStyle, backgroundColor: '#FFFC00' }}>
        <img src="/SnapchatIcon.png" alt="insta" />
    </div>
);
const TikTokIcon = (
    <div style={{ ...IconStyle, backgroundColor: '#000000' }}>
        <img src="/TikTokIcon.png" alt="insta" />
    </div>
);

function calculateAge(dateString) {
    const today = new Date(); // Current date
    const birthDate = new Date(dateString); // Convert the string into a date object
    let age = today.getFullYear() - birthDate.getFullYear(); // Calculate the year difference
    const monthDifference = today.getMonth() - birthDate.getMonth(); // Calculate the month difference

    // If the birthdate hasn't occurred yet this year, subtract 1 from the age
    if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
        age--;
    }

    return age;
}

const InfluencerDetail = () => {
    let { influencerId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [influencer, setInfluencer] = useState(null);
    const user = JSON.parse(localStorage.getItem('user'));
    const [formModal, setFormModal] = useState({ open: false });
    const [responseModal, setResponseModal] = useState({ open: false });
    const [disaprovalReason, setDisaprovalReason] = useState(true);

    console.log({ influencer });

    const instagramStats = influencer?.influencerStats.find(
        (inf) => inf?.platform === 'instagram',
    );
    const snapchatStats = influencer?.influencerStats.find(
        (inf) => inf?.platform === 'snapchat',
    );
    const tiktokStats = influencer?.influencerStats.find(
        (inf) => inf?.platform === 'tiktok',
    );

    useEffect(
        () => {
            if (influencerId) {
                setLoading(true);
                dev.get(`/admin/getAllInfluencers/${influencerId}`, {
                    headers: {
                        token: user.token,
                    },
                })
                    .then((response) => {
                        setLoading(false);
                        setInfluencer(response?.data?.data);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            }
        },
        //eslint-disable-next-line
        [influencerId],
    );

    const handleFormClose = (reason) => {
        if (!reason) setDisaprovalReason(false);
        else {
            setDisaprovalReason(true);

            rejectUser({
                email: formModal?.email,
                disapprovalReason: reason,
                id: formModal?.id,
            });
            setFormModal({ open: false });
        }
    };
    const handleClickOpen = (value) => {
        setFormModal({
            open: true,
            title: 'Send Disapproval Reason',
            error: true,
            email: influencer?.email,
            id: influencer?.id,
        });
    };

    const rejectUser = async (data) => {
        await dev
            .put(`/admin/disapproveUser`, data, {
                headers: {
                    token: user?.token,
                },
            })
            .then((response) => {
                if (response) {
                    setResponseModal({
                        open: true,
                        error: false,
                        title: response?.data?.message,
                    });
                }
            })
            .catch((error) => {
                setResponseModal({
                    open: true,
                    error: true,
                    title: error?.data?.errorr,
                });
            });
    };

    const ApproveUser = async () => {
        await dev
            .put(
                `/admin/approveUser`,
                { email: influencer?.email, id: influencer?.id },
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response) {
                    setResponseModal({
                        open: true,
                        error: false,
                        title: response?.data?.userUpdated
                            ? 'User Approved'
                            : '',
                    });
                }
            })
            .catch((error) => {
                setResponseModal({
                    open: true,
                    error: true,
                    title: error?.data?.errorr,
                });
            });
    };

    const handleResponseClose = () => {
        setResponseModal({ open: false });
        navigate('/influencer-records');
    };
    return (
        <Grid
            xs={12}
            sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
            }}
        >
            <FormRejectionDialog
                modal={formModal}
                onClose={handleFormClose}
                hasReason={disaprovalReason}
            />
            <FormSubmitDialog
                modal={responseModal}
                onClose={handleResponseClose}
            />
            <Loader loading={loading} />
            <HeaderWrapper
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <HeaderText
                    text="Influencer Profile"
                    color="#000000"
                    style={{
                        fontFamily: 'Red Hat Display',
                        fontSize: '32px',
                        fontWeight: 700,
                    }}
                />
                {!user?.user?.merchant && influencer?.isApproved === 0 && (
                    <Box
                        display={'flex'}
                        gap={'10px'}
                        sx={{
                            width: { xs: '150px', md: 'auto' },
                            marginRight: { xs: '10px', md: 0 },
                        }}
                    >
                        <PrimaryBtn
                            text="Approve"
                            onClick={ApproveUser}
                            bgColor="#01AB3B"
                            style={{
                                width: '132.5px',
                                height: '40px',
                                border: '1px solid #8D8D8D',
                            }}
                        />
                        <PrimaryBtn
                            text="Reject"
                            onClick={() => handleClickOpen(0)}
                            bgColor="#E94E51"
                            style={{ width: '132.5px', height: '40px' }}
                        />
                    </Box>
                )}
            </HeaderWrapper>
            <Grid
                mt={2}
                xs={12}
                sx={{
                    height: '626px',
                    borderRadius: '10px',
                    border: '1px solid #E9EDF4',
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                        background: '#FCFCFC',
                        borderBottom: '1px solid #E9EDF4',
                        padding: '20px',
                    }}
                >
                    <Typography
                        style={{
                            fontSize: '22px',
                            fontWeight: 600,
                            fontFamily: "'IBM Plex Sans'",
                        }}
                    >
                        Information & Details
                    </Typography>
                </Grid>
                <Box
                    sx={{
                        padding: '22px',
                    }}
                >
                    <Grid
                        container
                        mt={'20px'}
                        sx={{
                            overflow: 'hidden',
                        }}
                    >
                        <img
                            alt=""
                            src={influencer?.avatar}
                            width={120}
                            height={120}
                            style={{
                                border: '3px solid black',
                                borderRadius: '50%',
                                objectFit: 'contain',
                            }}
                        />
                    </Grid>
                    <Grid container mt={'20px'}>
                        <Typography
                            sx={{
                                fontFamily: 'Red Hat Display',
                                fontSize: '28px',
                                fontWeight: 500,
                                lineHeight: '37.04px',
                                color: '#393939',
                            }}
                        >
                            {influencer?.name}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" mt="20px">
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            md={5}
                            lg={4}
                            xl={4}
                            direction={'column'}
                        >
                            <p
                                style={{
                                    ...fontStyle,
                                }}
                            >
                                Phone number
                            </p>
                            <p
                                style={{
                                    ...fontStyle,

                                    fontSize: '18px',
                                    fontWeight: 600,
                                    color: '#393939',
                                }}
                            >
                                {influencer?.phoneNumber}
                            </p>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={4}
                            xl={4}
                            direction={'column'}
                        >
                            <p
                                style={{
                                    ...fontStyle,
                                }}
                            >
                                Date of birth
                            </p>
                            <p
                                style={{
                                    ...fontStyle,

                                    fontSize: '18px',
                                    fontWeight: 600,
                                    color: '#393939',
                                }}
                            >
                                {influencer?.dob}
                            </p>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={1}
                            md={1}
                            lg={4}
                            xl={4}
                            direction={'column'}
                        >
                            <p
                                style={{
                                    ...fontStyle,
                                }}
                            >
                                Age
                            </p>
                            <p
                                style={{
                                    ...fontStyle,

                                    fontSize: '18px',
                                    fontWeight: 600,
                                    color: '#393939',
                                }}
                            >
                                {influencer?.dob &&
                                    calculateAge(influencer?.dob)}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" mt={'10px'}>
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            md={5}
                            lg={4}
                            xl={4}
                            direction={'column'}
                        >
                            <p
                                style={{
                                    ...fontStyle,
                                }}
                            >
                                Email
                            </p>
                            <p
                                style={{
                                    ...fontStyle,

                                    fontSize: '18px',
                                    fontWeight: 600,
                                    color: '#393939',
                                }}
                            >
                                {influencer?.email}
                            </p>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={2}
                            md={2}
                            lg={4}
                            xl={4}
                            direction={'column'}
                        >
                            <p
                                style={{
                                    ...fontStyle,
                                }}
                            >
                                UAE Influencer License
                            </p>
                            <p
                                style={{
                                    ...fontStyle,

                                    fontSize: '18px',
                                    fontWeight: 600,
                                    color: '#393939',
                                }}
                            >
                                {influencer?.isLicensed ? 'Yes' : 'No'}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" mt={'10px'}>
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            md={5}
                            lg={4}
                            xl={4}
                            direction={'column'}
                        >
                            <p
                                style={{
                                    ...fontStyle,
                                }}
                            >
                                Gender
                            </p>
                            <p
                                style={{
                                    ...fontStyle,

                                    fontSize: '18px',
                                    fontWeight: 600,
                                    color: '#393939',
                                }}
                            >
                                {influencer?.gender}
                            </p>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={2}
                            md={2}
                            lg={4}
                            xl={4}
                            direction={'column'}
                        >
                            <p
                                style={{
                                    ...fontStyle,
                                }}
                            >
                                Content category
                            </p>
                            <p
                                style={{
                                    ...fontStyle,

                                    fontSize: '18px',
                                    fontWeight: 600,
                                    color: '#393939',
                                }}
                            >
                                {influencer?.categories?.join(',')}
                            </p>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

            {instagramStats && (
                <Grid
                    xs={12}
                    sx={{
                        height: '201px',
                        borderRadius: '10px',
                        border: '1px solid #E9EDF4',
                        padding: '40px',
                    }}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {InstagramIcon}
                            <Typography
                                sx={{
                                    marginLeft: '10px',
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontWeight: 500,
                                    fontSize: '13.35px',
                                }}
                            >
                                {instagramStats?.handle}
                            </Typography>
                        </Box>
                        <Grid xs={12}>
                            <Grid
                                xs={12}
                                sm={12}
                                mt="20px"
                                display={'flex'}
                                sx={{
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    alignItems: { xs: 'start', sm: 'center' },
                                }}
                                justifyContent={'space-between'}
                            >
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        No. of Posts
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {instagramStats?.post_count}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Avg Likes
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {instagramStats?.average_likes}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Most Likes
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {instagramStats?.average_likes}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Story Views
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {instagramStats?.average_views}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Avg Comments
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {instagramStats?.average_comments}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Total Followers
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {instagramStats?.followers_count}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Engagement Rate
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {instagramStats?.overall_engagement}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {snapchatStats && (
                <Grid
                    xs={12}
                    sx={{
                        height: '201px',
                        borderRadius: '10px',
                        border: '1px solid #E9EDF4',
                        padding: '40px',
                    }}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {SnapchatIcon}
                            <Typography
                                sx={{
                                    marginLeft: '10px',
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontWeight: 500,
                                    fontSize: '13.35px',
                                }}
                            >
                                {snapchatStats?.handle}
                            </Typography>
                        </Box>
                        <Grid xs={12}>
                            <Grid
                                xs={12}
                                sm={8}
                                mt="20px"
                                display={'flex'}
                                sx={{
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    alignItems: { xs: 'start', sm: 'center' },
                                }}
                                justifyContent={'space-between'}
                            >
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Subscribers
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {snapchatStats?.following_count}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Avg Snap Views
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {snapchatStats?.average_views}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Avg Story Views
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {snapchatStats?.average_views}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Avg Screenshots
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {snapchatStats?.average_screenshots ||
                                            0}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Engagement Rate
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {snapchatStats?.overall_engagement}%
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {tiktokStats && (
                <Grid
                    xs={12}
                    sx={{
                        height: '201px',
                        borderRadius: '10px',
                        border: '1px solid #E9EDF4',
                        padding: '40px',
                    }}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {TikTokIcon}
                            <Typography
                                sx={{
                                    marginLeft: '10px',
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontWeight: 500,
                                    fontSize: '13.35px',
                                }}
                            >
                                {tiktokStats?.handle}
                            </Typography>
                        </Box>
                        <Grid xs={12}>
                            <Grid
                                xs={12}
                                sm={12}
                                mt="20px"
                                display={'flex'}
                                sx={{
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    alignItems: { xs: 'start', sm: 'center' },
                                }}
                                justifyContent={'space-between'}
                            >
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        No. of Posts
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {tiktokStats?.post_count}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Avg Likes
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {tiktokStats?.average_likes}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Most Likes
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {tiktokStats?.average_likes}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Story Views
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {tiktokStats?.average_views}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Avg Comments
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {tiktokStats?.average_comments}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Total Followers
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {tiktokStats?.followers_count}
                                    </Typography>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        height: '25px',
                                        background: '#E7E5E5',
                                    }}
                                />
                                <Grid direction={'column'}>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#000000',
                                        }}
                                    >
                                        Engagement Rate
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...fontStyle,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        {tiktokStats?.overall_engagement}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {/* <Grid
                container
                spacing={2} // Add spacing between Grid items
                xs={12}
                sx={{
                    display: 'flex',
                }}
            >
                <Grid item xs={12} sm={3}>
                    <Grid
                        container // Change to container
                        display="flex"
                        backgroundColor="#FCFCFC"
                        border="1px solid #E9EDF4"
                        borderRadius="10px"
                        sx={{ minHeight: '160px' }}
                    >
                        <Grid
                            item // Add item
                            width={'100%'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            flexDirection={'column'}
                        >
                            <Typography
                                style={{
                                    ...fontStyle,
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: '28.6px',
                                    color: '#8D8D8D',
                                }}
                            >
                                Engagement Rate
                            </Typography>
                            <Typography
                                style={{
                                    ...fontStyle,
                                    fontSize: '28px',
                                    fontWeight: 700,
                                    lineHeight: '22.6px',
                                }}
                            >
                                {influencer?.influencerStat?.engagementRate ||
                                    0}
                                %
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Grid
                        container
                        display="flex"
                        backgroundColor="#FCFCFC"
                        border="1px solid #E9EDF4"
                        borderRadius="10px"
                        sx={{ minHeight: '160px' }}
                    >
                        <Grid
                            item
                            width={'100%'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            flexDirection={'column'}
                        >
                            <Typography
                                style={{
                                    ...fontStyle,
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: '28.6px',
                                    color: '#8D8D8D',
                                }}
                            >
                                Total Followers
                            </Typography>
                            <Typography
                                style={{
                                    ...fontStyle,
                                    fontSize: '28px',
                                    fontWeight: 700,
                                    lineHeight: '22.6px',
                                }}
                            >
                                {influencer?.influencerStat?.growthRate || 0}%
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
        </Grid>
    );
};

export default InfluencerDetail;
